<template>
  <div class="container-fluid">
    <d-body-top-bar :title="$t('admin.club_new.title')">
      <div slot="tree">
        <label class="pointer link-area ml-1"
               @click="$router.push({ name: 'clubs'})"
        >
          {{ $t('admin.clubs.list') }} /
        </label>
        <label
            class="pointer inactive-tree link-area ml-1"
            @click="$router.push({ name: 'clients_list'})"
        >
          {{ $t('admin.club_new.new_club') }}
        </label>
      </div>
      <div slot="icons">
      <span
          class="active-tree icons-body-size"
      >
        <i class="fa fa-navicon"></i>
      </span>
      </div>
    </d-body-top-bar>
    <div class="container-fluid">
      <b-card>
        <validation-observer
            slim
            ref="customerObserver"
            v-slot="{ invalid }"
        >
          <club-form
              :club="club"
              :validation="validation"
          />
          <b-row class="mt-2">
            <b-col align="right">

              <d-button
                v-if="!isUpdate"
                text="general.actions.save"
                class="d-btn-sm font-text-title btn d-btn-danger "
                @on:button-click="addClub"
              />
              <d-button
                v-if="isUpdate"
                text="general.actions.update"
                class="d-btn-sm font-text-title btn d-btn-danger"
                @on:button-click="updateClub"
              />
            </b-col>
          </b-row>
        </validation-observer>
      </b-card>
    </div>
  </div>
</template>
<script>
import Club from "@/classes/Club";
import {getClub, postClub, putClub} from "@api/services/club/club.api";

export default {
  data: () => ({
    isUpdate: false,
    club: new Club(),
    validation: require('@validation/entities/ClubAdmin.json')
  }),
  components: {
    ClubForm: () => import('./ClubForm'),
  },
  created() {
    this.club.address = '';
    if (this.$route.params.id) {
      this.isUpdate = true;
      getClub('/clubs/' + this.$route.params.id).then(response => {
        if (response.data) {
          this.club = response.data;
          if (this.club.address) {
            if (this.club.address.length > 0) {
              this.club.address = this.club.address[0];
            }
          }
        }
      }).catch(err => {
        console.error('error : ', err);
      })
    }

  },
  methods: {
    getCols() {
      const width = this.$store.getters["layout/getInnerWidth"];

      if (width < 1368) {
        return '12';
      }
      return '6';
    },
    serializeClub(club) {
      return {
        address: club.address,
        city: club.city,
        country: club.country,
        currency: club.currency,
        deepLink: club.deepLink,
        description: club.description,
        email: club.email,
        enableUserClientWallet: club.enableUserClientWallet,
        fixPhoneNumber: club.fixPhoneNumber,
        latitude: parseFloat(club.latitude),
        locale: club.locale,
        longitude: parseFloat(club.longitude),
        mobilePhoneNumber: club.mobilePhoneNumber,
        name: club.name,
        timezone: club.timezone,
        webSiteUrl: club.webSiteUrl,
        smsAccountBalance: parseInt(club.smsAccountBalance),
        zipCode: club.zipCode,
        appSportTimetableTypes: club.appSportTimetableTypes,
      };
    },
    updateClub() {
      try {

        this.$refs.customerObserver.validate().then((valid) => {
          if (valid) {
            this.club.address = [this.club.address];
            delete this.club.photos;
            const data = this.serializeClub(this.club);
            putClub(this.club.id, data).then(resp => {
              if (resp) {
                this.$vs.notify({ time: 10000, title: this.$t('general.actions.notification') ,text: this.$t('general.actions.success-update'), color: 'success',position: 'top-right'});
                this.$router.push('/clubs/detail/' + this.club.id);
              } else {
                this.club.address = this.club.address[0];
              }
            }).catch(err => {
              this.club.address = this.club.address[0];
              if (err.data.violations) {
                err.data.violations.forEach(item => {
                  this.$vs.notify({ time: 10000, title: item.propertyPath ,text:  item.message, color: 'warning', position: 'top-right'});
                });
              } else {
                this.$vs.notify({ time: 10000, title: err.data["hydra:title"] ,text:  err.data["hydra:description"], color: 'warning', position: 'top-right'});

              }
              console.error(err);
            });
          }
        })

      } catch (err) {}

    },
    addClub () {
      try {
        this.$refs.customerObserver.validate().then((valid) => {
          if (valid) {
            this.club.address = [this.club.address];
            delete this.club.photos;
            postClub(this.club).then(resp => {
              if (resp) {
                this.$router.push({name: 'nextore_form', params: {id: resp.data.id}});
              } else {
                this.club.address = this.club.address[0];
              }
            }).catch(err => {
              this.club.address = this.club.address[0];
              if (err.data.violations) {
                err.data.violations.forEach(item => {
                  this.$vs.notify({ time: 10000, title: item.propertyPath ,text:  item.message, color: 'warning', position: 'top-right'});
                });
              } else {
                this.$vs.notify({ time: 10000, title: err.data["hydra:title"] ,text:  err.data["hydra:description"], color: 'warning', position: 'top-right'});

              }
              console.error(err);
            });
          }
        })
      } catch (e) {}
    }
  }
}
</script>
<style scoped>
.card {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 10px 20px #C4C8D066;
  border-radius: 8px;
  opacity: 1;
}

.card .card-body {
  padding-top: 12px;
  padding-left: 35px;
  padding-right: 25px;
  padding-bottom: 12px;
  background-color: transparent;
}

.card .card-header {
  background-color: #fff;
  padding-top: 5px;
  padding-left: 25px;
  padding-right: 25px;
  padding-bottom: 5px;
  border-bottom: unset;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}
</style>

